import { MAINTAIN_PERMISSION, DELETE_PERMISSION, GROWERS_ATTRIBUTES } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "Attribute",
      "search-by": "Code,Description,ShortDescription",
      "ph-search-by": "Search by Attribute Code, Attribute Description and Attribute Short Description"
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function(_ref) {
        var edit = _ref.edit, record = _ref.record, toggleActive = _ref.toggleActive;
        return [_vm.hasMaintainPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Edit")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          class: {
            "mr-1": record.isInactive,
            "mr-2": !record.isInactive
          },
          attrs: {
            "disabled": record.isInactive,
            "icon": "edit",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return edit(record);
            }
          }
        })], 1) : _vm._e(), _vm.hasDeletePermission && record.sampleType != _vm.NON_DEACTIVATABLE_SAMPLE_TYPE ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v(_vm._s(record.isInactive ? "Activate" : "Deactivate"))];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": record.isInactive ? "eye-invisible" : "eye",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return toggleActive(record);
            }
          }
        })], 1) : _vm._e()];
      }
    }])
  }, [_c("text-field", {
    key: "CropCode",
    attrs: {
      "data-index": "cropCode",
      "title": "Crop Code",
      "sorter": true
    }
  }), _c("text-field", {
    key: "Code",
    attrs: {
      "data-index": "code",
      "title": "Attribute Code",
      "sorter": true
    }
  }), _c("text-field", {
    key: "Description",
    attrs: {
      "data-index": "description",
      "title": "Attribute Description"
    }
  }), _c("text-field", {
    key: "ShortDescription",
    attrs: {
      "data-index": "shortDescription",
      "title": "Attribute Short Description"
    }
  }), _c("text-field", {
    key: "SampleType",
    attrs: {
      "data-index": "sampleType",
      "title": "Sample Type",
      "sorter": true
    }
  }), _c("text-field", {
    key: "Sequence",
    attrs: {
      "data-index": "sequence",
      "title": "Sequence",
      "sorter": true
    }
  }), _c("text-field", {
    key: "JagPrompt",
    attrs: {
      "data-index": "jagPrompt",
      "title": "Scale Prompt",
      "sorter": true
    }
  }), _c("icon-field", {
    key: "WarningTolerance",
    attrs: {
      "data-index": "warningTolerance",
      "title": "Warning Tolerance",
      "align": "center",
      "render-icon": _vm.renderIcon,
      "sorter": true
    }
  }), _c("text-field", {
    key: "WarningToleranceLimit",
    attrs: {
      "data-index": "warningToleranceLimit",
      "title": "Tolerance Limit",
      "sorter": true
    }
  }), _c("text-field", {
    key: "WarningText",
    attrs: {
      "data-index": "warningText",
      "title": "Warning Text",
      "sorter": true
    }
  }), _c("icon-field", {
    key: "ManualEntry",
    attrs: {
      "data-index": "manualEntry",
      "title": "Manual Entry",
      "align": "center",
      "render-icon": _vm.renderIcon,
      "sorter": true
    }
  })], 1);
};
var staticRenderFns$1 = [];
const NON_DEACTIVATABLE_SAMPLE_TYPE = "1";
const __vue2_script$1 = {
  name: "ListAttribute",
  inject: ["can"],
  data() {
    return {
      NON_DEACTIVATABLE_SAMPLE_TYPE
    };
  },
  computed: {
    hasMaintainPermission() {
      return this.can(MAINTAIN_PERMISSION);
    },
    hasDeletePermission() {
      return this.can(DELETE_PERMISSION);
    }
  },
  methods: {
    renderIcon(value) {
      if (!value) {
        return "";
      }
      return "check-circle";
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListAttribute = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-attribute"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.attributes",
      "api-url": _vm.apiUrl,
      "create-route": "/watties-grower/attributes/create",
      "edit-route": "/watties-grower/attributes/:id",
      "filter-route": "/watties-grower/attributes/advanced-search",
      "page": _vm.page
    }
  }, [_c("list-attribute")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "Attribute",
  components: {
    ListAttribute
  },
  data() {
    return {
      ListAttribute,
      apiUrl,
      page: GROWERS_ATTRIBUTES,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "attributes",
          title: "Attributes",
          path: "/watties-grower/attributes"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
